// extracted by mini-css-extract-plugin
export var additional = "TwitterPost-module--additional--CCLME";
export var additional__comments = "TwitterPost-module--additional__comments--zkN8L";
export var additional__img = "TwitterPost-module--additional__img--KAi5z";
export var additional__likes = "TwitterPost-module--additional__likes--Mlp96";
export var additional__text = "TwitterPost-module--additional__text--vtxjp";
export var additional__time = "TwitterPost-module--additional__time--rUEdG";
export var media = "TwitterPost-module--media--operO";
export var message = "TwitterPost-module--message--6M+ot";
export var mobile__hidden = "TwitterPost-module--mobile__hidden--nSGoL";
export var post = "TwitterPost-module--post--0GFOi";
export var post__avatar = "TwitterPost-module--post__avatar--zbKtW";
export var post__head = "TwitterPost-module--post__head--Mi3It";
export var post__login = "TwitterPost-module--post__login--jkiXm";
export var post__logo = "TwitterPost-module--post__logo--pLt2Q";
export var post__name = "TwitterPost-module--post__name--6VcoC";
export var post__names = "TwitterPost-module--post__names--zQXyX";
export var post_big = "TwitterPost-module--post_big--CpEGw";