import React from "react"
import classnames from "classnames"
import * as cl from "./Card.module.css"

const Card = ({ className, flipped, size, media, content }) => {
  return (
    <div className={classnames(cl.card, { [cl.small]: size === 'small', [cl.flipped]: flipped }, className)}>
      <div className={cl.media}>
        {media}
      </div>

      <div className={cl.content}>
        {content}
      </div>
    </div>
  );
}

export default Card
