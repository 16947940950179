import React from "react"
import BrowserLink from "../BrowserLink";
import * as cl from "./Footer.module.css"

const Footer = () => {
  return (
    <footer className={cl.footer}>
      <div className={cl.content}>
        <p>Copyright © 2024 PokerCoaching.com. All Rights Reserved.</p>
        <p><a href="https://www.ncpgambling.org/" target="_blank">Please gamble responsibly.</a></p>
        <nav className={cl.menu}>
          <ul>
            <li><BrowserLink href="/privacy-policy/">Privacy Policy</BrowserLink></li>
            <li><BrowserLink href="/pages-legal-policies/">Legal & Policies</BrowserLink></li>
            <li><BrowserLink href="/contact-us/">Support</BrowserLink></li>
          </ul>
        </nav>
      </div>
    </footer>
  )
}

export default Footer
