import React from "react"
import classnames from "classnames"
import Image from "../../Image"
import twitterLogo from "/src/assets/images/Twitter/twitter_logo.svg"
import likesImg from "/src/assets/images/Twitter/likes.svg"
import commentImg from "/src/assets/images/Twitter/comment.svg"
import * as cl from "./TwitterPost.module.css"

const TwitterPost = ({ className, size, user, post, footer, order, position, id }) => {
  if (!user) return null

  return (
    <article
      className={classnames(cl.post, { [cl.post_big]: size === 'big' }, className)}
      style={{ order }}
      data-sal={position ? `slide-${position === "left" ? "right" : "left"}` : 'fade'}
      data-sal-duration="500"
      data-sal-easing="ease"
    >
      {user.name === '' ? (
        <Image src={`Twitts/post-${id}.jpeg`} alt="Sunset Image" />
      ) : (
        <>
          <header className={cl.post__head}>
            <Image src={`Twitts/avatar-${user.login}.jpg`} alt="avatar" className={cl.post__avatar} />
            <div className={cl.post__names}>
              <div className={cl.post__name}>{user.name}</div>
              <div className={cl.post__login}>{user.login}</div>
            </div>
            <img
              src={twitterLogo}
              alt="twitter logo poker"
              className={cl.post__logo}
            />
          </header>

          <div className={cl.message} dangerouslySetInnerHTML={{__html: post.text}} />

          {post.imageURL && (
            <div className={cl.media}>
              <Image src={`Twitts/post-${id}.jpeg`} alt="Sunset Image" />
            </div>
          )}

          {post.mediaHTML && (
            <div className={cl.media} dangerouslySetInnerHTML={{__html: post.mediaHTML}} />
          )}

          {footer && (
            <footer className={cl.additional}>
              <time className={cl.additional__text}>{footer.time}</time>

              <div className={cl.additional__likes}>
                {footer.likes && (
                  <img
                    src={likesImg}
                    alt="poker twitter likes"
                    className={cl.additional__img}
                  />
                )}
                <span className={cl.additional__text}>{footer.likes}</span>
              </div>
              <div className={cl.additional__comments}>
                {footer.comments && (
                  <>
                    <img
                      src={commentImg}
                      alt="poker twitter comment"
                      className={cl.additional__img}
                    />
                    <span className={cl.additional__text}>
                    {footer.comments}{" "}
                      <span className={cl.mobile__hidden}>
                    {" "}
                        people are talking about this
                    </span>
                  </span>
                  </>
                )}
              </div>
            </footer>
          )}
        </>
      )}
    </article>
  )
}

export default TwitterPost
